import React, { useContext } from 'react';
import { Badge, Container } from 'react-bootstrap';
import Navbar from '../../components/Navbar';
import CONFIG from '../../config';
import { Link } from 'react-router-dom';
import Search from '../../images/Search';
import Applications from '../../images/Applications';
import Loans from '../../images/Loans';
import Disbursements from '../../images/Disbursements';
import System from '../../images/System';
import AdminContext from '../../adminContext';
import styles from './Layout.module.scss';
import cx from 'classnames';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useSWRConfig } from 'swr';

type Props = {
  selectedTab?: string;
  viewComponent: React.ReactNode;
};

function EnvBadge () {
  let variant = 'primary';
  let badgeText = 'Local';
  switch (CONFIG.ENVIRONMENT) {
    case 'prod':
      variant = 'danger';
      badgeText = 'Production';
      break;
    case 'dev':
      badgeText = 'Staging';
      break;
    case 'test':
      badgeText = 'Test';
      break;
  }

  return (
    <Badge className={cx(styles.headerBadge, styles[variant])} variant={variant as any}>{badgeText}</Badge>
  );
}

function SidebarHeader () {
  return (
    <Link className={styles.sidebarHeader} to='/'>
      <span className='mr-3'>Notebook</span>
      <EnvBadge/>
    </Link>
  );
}

function NavLink ({ icon, to, selected, children }: { icon: JSX.Element, to: string, selected: boolean, children: React.ReactNode }) {
  return (
    <Link className={cx(styles.sidebarLink, selected ? styles.selected : '')} to={to}>
      <div className='d-flex align-items-center'>
        {icon}
        <div className='ml-2'>{children}</div>
      </div>
    </Link>
  );
}

function AdminProfile () {
  const { callAPI } = useAdminAPICall({ endpoint: '/notebook/logout', method: 'POST' });
  const { mutate } = useSWRConfig();

  const onLogout = async () => {
    await callAPI();
    mutate('/notebook/admins/self');
  };

  return (
    <div className='d-flex align-items-center mt-auto pl-2'>
      <img src='https://ca.slack-edge.com/T5GM16XS4-U5HCV3XBN-64f0f9bbd465-512' alt='Admin' className={styles.adminImage}/>
      <div className={cx('ml-2', styles.adminName)}>
        <div>Austin Lane</div>
        <div className='text-muted cursor-pointer' onClick={onLogout}>Sign out</div>
      </div>
    </div>
  );
}

function Sidebar ({ selectedTab }: { selectedTab?: string }) {
  return (
    <div className={styles.sidebar}>
      <div className='d-flex h-100 flex-column'>
        <SidebarHeader />
        <div className='mt-4'>
          <NavLink icon={<Search/>} selected={selectedTab === 'search'} to='/'>Search</NavLink>
          <NavLink icon={<Applications/>} selected={selectedTab === 'applications'} to='/loan-applications'>Applications</NavLink>
          <NavLink icon={<Loans/>} selected={selectedTab === 'loans'} to='/loans'>Loans</NavLink>
          <NavLink icon={<Disbursements/>} selected={selectedTab === 'disbursements'} to='/disbursements'>Disbursements</NavLink>
          <NavLink icon={<System/>} selected={selectedTab === 'system'} to='/system/partners'>System</NavLink>
        </div>
        <AdminProfile />
      </div>
    </div>
  );
}

export default function Layout ({ viewComponent, selectedTab }: Props) {
  const adminContext = useContext(AdminContext);
  if (adminContext.sidebarLayoutEnabled) {
    return (
      <>
        <Sidebar selectedTab={selectedTab}/>
        <div className={styles.viewComponent}>
          <Container>
            {viewComponent}
          </Container>
        </div>
      </>
    );
  }

  return (
    <Container className='rootContainer'>
      <Navbar/>
      {viewComponent}
    </Container>
  );
}
