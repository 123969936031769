import React from 'react';

export default React.createContext({
  adminId: '',
  adminRole: '',
  sidebarLayoutEnabled: false,
  canEditSystemSettings: false,
});

export const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
};
